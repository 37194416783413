import { InjectionToken, Provider, Type } from '@angular/core';

import { LoadOptions } from './loaders.client-config';

/**
 * Token used to provide your loader.
 *
 * @deprecated
 */
export const LOADER_TOKEN = new InjectionToken<Loader[]>('vn-loader');

/**
 * @whatItDoes Registers loader.
 *
 * @deprecated
 */
export function registerLoader<T extends Loader>(type: Type<T>): Provider[] {
    return [
        type,
        {
            provide: LOADER_TOKEN,
            multi: true,
            useExisting: type,
        },
    ];
}

/**
 * Token used to provide your loader function.
 *
 * @experimental
 */
export const LOADER_FN_TOKEN = new InjectionToken<LoaderFnConfig[]>('vn-loader-fn');

export interface LoaderFnConfig {
    id: string;
    loaderFn: () => Promise<any>;
}

/**
 * @whatItDoes Registers loader functions.
 *
 * @stable
 */
export function registerLoaderFn(config: LoaderFnConfig): Provider {
    return [
        {
            provide: LOADER_FN_TOKEN,
            multi: true,
            useValue: config,
        },
    ];
}

/**
 * Defines interface of loader.
 *
 * @experimental
 */
export interface Loader {
    id: string;

    load(options: LoadOptions): Promise<void>;
}

export enum LoadState {
    Created = 'created',
    Scheduled = 'scheduled',
    Enabled = 'enabled',
    Loaded = 'loaded',
}

export interface LoaderLoadOptions {
    id: string;
    loader?: Loader;
    loaderFn?: () => Promise<any>;
    state: LoadState;
    options: LoadOptions;
}

export enum LoadStrategy {
    /** represents a strategy that is always fired first. triggered only once */
    Preload = 'preload',
    /** represents a strategy that is used when user just logged in (client side login). triggered only once */
    JustLoggedIn = 'just-loggedin',
    /** represents a strategy that is used when user was already logged in on server side. triggered only once */
    AlreadyLoggedIn = 'already-loggedin',
    /** union of just and already logged in. triggered only once */
    LoggedIn = 'loggedin',
    /** represents a strategy that is used document ready state is complete (similar to onload document event) . triggered only once*/
    Complete = 'complete',
    /** represents a strategy that is triggered on CCB, rtms or vanilla event. triggered multiple times */
    Event = 'event',
}
