import { Inject, Injectable, Injector, Optional } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
    DslEnvService,
    DslService,
    EventContext,
    EventType,
    EventsService,
    Logger,
    NativeAppService,
    NativeEvent,
    NativeEventType,
    RtmsMessage,
    RtmsService,
    SimpleEvent,
    UserEvent,
    UserLoginEvent,
    UserService,
    VanillaEventNames,
    WebWorkerService,
    WindowEvent,
    WindowRef,
} from '@frontend/vanilla/core';
import { Subject, first } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { LoaderStylesService } from './loader-styles.service';
import { LoadOptions, LoadersConfig } from './loaders.client-config';
import { LOADER_FN_TOKEN, LOADER_TOKEN, LoadState, LoadStrategy, Loader, LoaderFnConfig, LoaderLoadOptions } from './loaders.models';
import { ModuleService } from './module.service';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    readonly loadDispatcher: Subject<LoaderLoadOptions> = new Subject();

    private readonly STYLES_LOADER_ID = 'styles';
    private readonly allLoaders: LoaderLoadOptions[] = [];

    constructor(
        @Optional() @Inject(LOADER_TOKEN) private registeredLoaders: Loader[],
        @Inject(LOADER_FN_TOKEN) private registeredLoaderFns: LoaderFnConfig[],
        private config: LoadersConfig,
        private dslService: DslService,
        private dslEnvService: DslEnvService,
        private user: UserService,
        private webWorkerService: WebWorkerService,
        private nativeAppService: NativeAppService,
        private logger: Logger,
        private rtmsService: RtmsService,
        private eventsService: EventsService,
        private windowRef: WindowRef,
        private injector: Injector,
        private moduleService: ModuleService,
        private nativeApp: NativeAppService,
        private loadStylesService: LoaderStylesService,
    ) {}

    init() {
        this.initLoaders();

        this.loadDispatcher.subscribe((options: LoaderLoadOptions) => this.load(options));

        // start just logged-in strategy when user logs in (only once)
        this.user.events.pipe(first((e: UserEvent) => e instanceof UserLoginEvent)).subscribe(() => {
            this.start(LoadStrategy.JustLoggedIn);
            this.start(LoadStrategy.LoggedIn);
        });

        // re-evaluate DSL conditions on any DSL change
        this.dslEnvService.change.pipe(debounceTime(100)).subscribe(() => {
            this.restart();
        });

        // Vanilla events
        this.eventsService.events.subscribe((e: SimpleEvent) => {
            const event: EventContext<any> = { name: e.eventName, type: EventType.Vanilla, data: e.data };
            this.startEventStrategy(event);
        });

        // RTMS events
        this.rtmsService.messages.subscribe((e: RtmsMessage) => {
            const event: EventContext<any> = { name: e.type, type: EventType.Rtms, data: e.payload };
            this.startEventStrategy(event);
        });

        // Native events
        this.nativeAppService.eventsFromNative.subscribe((e: NativeEvent) => {
            const event: EventContext<any> = { name: e.eventName, type: EventType.Native, data: e.parameters };
            this.startEventStrategy(event);
        });

        // preload always first
        this.start(LoadStrategy.Preload);

        // then loggedin, if authenticated
        if (this.user.isAuthenticated) {
            this.start(LoadStrategy.AlreadyLoggedIn);
            this.start(LoadStrategy.LoggedIn);
        }

        // then complete
        this.windowRef.nativeWindow.document.addEventListener(WindowEvent.ReadyStateChange, () => this.runOnlyCompleteStrategy());
        this.runOnlyCompleteStrategy();
    }

    async loadFeature(featureId: string, featureImport: Promise<any>, options: LoadOptions) {
        await Promise.all([
            featureImport.then((feature: any) => {
                const featureInjector = Injector.create({
                    providers: feature.provide(),
                    parent: this.injector,
                });

                this.moduleService.runBootstrappers(featureInjector);
                this.moduleService.runDslProviders(featureInjector);

                if (options.eventContext) {
                    this.moduleService.runEventProcessors(featureInjector, options.eventContext);
                }

                this.nativeApp.sendToNative({
                    eventName: NativeEventType.FEATURE_LOADED,
                    parameters: { featureId },
                });
            }),
            this.loadStylesService.loadStyles(options.styles),
        ]);
    }

    private runOnlyCompleteStrategy() {
        const strategy = this.windowRef.nativeWindow.document.readyState;

        if (strategy === LoadStrategy.Complete) {
            this.start(strategy);
        }
    }

    private start(strategy: string) {
        const loaders = this.allLoaders.filter((l: LoaderLoadOptions) => l.options.strategy === strategy);

        for (const item of loaders) {
            item.state = LoadState.Scheduled;
        }

        if (loaders.length > 0) {
            this.logger.info(`Scheduled ${loaders.length} loader(s) with ${strategy} strategy.`);
        }

        this.evaluate(loaders);
    }

    private startEventStrategy(event: EventContext<any>) {
        const loaders = this.allLoaders.filter(
            (l: LoaderLoadOptions) => l.options.strategy == LoadStrategy.Event && l.options.events?.[event.type]?.includes(event.name),
        );

        if (loaders.length > 0) {
            this.logger.info(`Found ${loaders.length} event handler(s) for event.`, loaders, event);
        }

        loaders.forEach((loader: LoaderLoadOptions) => this.load(loader, event));
    }

    private restart() {
        const scheduled = this.allLoaders.filter(
            (l: LoaderLoadOptions) =>
                l.state === LoadState.Scheduled &&
                ![LoadStrategy.JustLoggedIn.toString(), LoadStrategy.LoggedIn.toString()].includes(l.options.strategy),
        );
        this.evaluate(scheduled);
    }

    private initLoaders() {
        for (const ruleId of Object.keys(this.config.rules)) {
            const loaderId = this.config.rules[ruleId]?.id ?? ruleId;
            const options = this.config.rules[ruleId]!;

            // styles are a special case
            if (loaderId === this.STYLES_LOADER_ID) {
                this.loadStylesService.loadStyles(options.styles).then();
                continue;
            }

            const loaderFromProviders = this.registeredLoaders?.find((l: Loader) => l.id === loaderId);

            if (loaderFromProviders) {
                this.allLoaders.push({
                    id: loaderId,
                    loader: loaderFromProviders,
                    state: LoadState.Created,
                    options,
                });
                continue;
            }

            const loaderFnConfig = this.registeredLoaderFns.find((l: LoaderFnConfig) => l.id === loaderId);

            if (loaderFnConfig) {
                this.allLoaders.push({
                    id: loaderId,
                    loaderFn: loaderFnConfig.loaderFn,
                    state: LoadState.Created,
                    options,
                });
            }
        }
    }

    private evaluate(loaders: LoaderLoadOptions[]) {
        for (const loader of loaders) {
            this.dslService
                .evaluateExpression<boolean>(loader.options.enabled)
                .pipe(first())
                .subscribe((enabled: boolean) => {
                    if (enabled) {
                        if (loader.state == LoadState.Scheduled) {
                            loader.state = LoadState.Enabled;
                            this.loadDispatcher.next(loader);
                        }
                    }
                    this.eventsService.raise({ eventName: VanillaEventNames.FeatureEnabledStatus, data: { id: loader.id, enabled } });
                });
        }
    }

    private load(item: LoaderLoadOptions, eventContext?: EventContext<any>) {
        try {
            const options: LoadOptions = {
                ...item.options,
            };

            if (eventContext) {
                options.eventContext = eventContext;
            }

            if (options.delay) {
                this.webWorkerService.createWorker(item.id, { timeout: options.delay }, () => {
                    this.runLoader(item, options);
                    this.webWorkerService.removeWorker(item.id);
                });
            } else {
                this.runLoader(item, options);
            }
        } catch (error) {
            this.logger.error('Failed to load feature loader.', item, error);
        }

        this.logger.info('Loaded:', item);
    }

    private runLoader(item: LoaderLoadOptions, options: LoadOptions) {
        if (item.loader) {
            item.loader.load(options);
        } else if (item.loaderFn) {
            this.loadFeature(item.id, item.loaderFn(), options);
        }

        item.state = LoadState.Loaded;
    }
}
