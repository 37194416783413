import { Provider } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { runOnAppInit } from '@frontend/vanilla/core';

import { LoadersBootstrapService } from './loaders-bootstrap.service';
import { VANILLA_LOADERS_MAP } from './loaders-map';
import { registerLoaderFn } from './loaders.models';

/**
 * Provides providers of loaders package.
 *
 * @stable
 */
export function provideLoaders(): Provider[] {
    const vanillaLoadersFns = Object.keys(VANILLA_LOADERS_MAP).map((id: string) => registerLoaderFn({ id, loaderFn: VANILLA_LOADERS_MAP[id]! }));

    return [...vanillaLoadersFns, runOnAppInit(LoadersBootstrapService)];
}
