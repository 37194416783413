<div id="rtms-client-promotion">
    <div class="vc-rtms-overlay-client-promotion-container" *ngIf="isOverlay; else toaster">
        <div class="pk-overlay-promo">
            <div class="pk-overlay-image">
                <img [src]="overlayImage" alt="Overlay Image" />
            </div>
            <div class="pk-overlay-content">
                <div class="pk-overlay-title line-clamp" vnDynamicHtml="{{ overlayTitle }}"></div>
                <div class="pk-overlay-description" vnDynamicHtml="{{ overLayDescription }}"></div>
                <a
                    href="javascript:void(0);"
                    (click)="linkToAction(overlayCtaDetails)"
                    class="btn btn-primary more-info-right"
                    vnDynamicHtml="{{ overlayCTA }}"></a>
                <div class="pk-overlay-terms line-clamp" vnDynamicHtml="{{ overlayManualTermsAndConditions }}"></div>
            </div>
        </div>
    </div>

    <ng-template #toaster>
        <div class="vc-rtms-toaster-client-promotion-container">
            <div class="pk-toaster-image">
                <img [src]="tosterImage" alt="Toaster Image" />
            </div>

            <div class="pk-toaster-content">
                <div class="pk-toaster-description line-clamp" vnDynamicHtml="{{ toasterDescription }}"></div>
                <a href="javascript:void(0);" (click)="linkToAction(toasterCtaDetails)" target="_blank" vnDynamicHtml="{{ toasterCTA }}"></a>
            </div>
        </div>
    </ng-template>
</div>
