import { Injectable } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ClientConfigProductName, ContentService, HtmlNode, Page, StylesService, WebpackManifestService } from '@frontend/vanilla/core';
import { firstValueFrom } from 'rxjs';

import { StyleOptions } from './loaders.client-config';

/**
 * @experimental
 */
@Injectable({
    providedIn: 'root',
})
export class LoaderStylesService {
    constructor(
        private webpackManifestService: WebpackManifestService,
        private page: Page,
        private stylesService: StylesService,
        private contentService: ContentService,
        private htmlNode: HtmlNode,
    ) {}

    async loadStyles(styles?: StyleOptions[]) {
        if (!styles) return;
        for await (const style of styles) {
            if (style.htmlCssClass) {
                this.htmlNode.setCssClass(style.htmlCssClass, true);
            }
            switch (style.type) {
                case 'url':
                    await this.stylesService.loadStyle({ url: style.id, lazyLoad: 'Important' });
                    break;
                case 'theme':
                    let filename = this.getNewThemeFilename(style.id);
                    let themesCssPath = await this.webpackManifestService.getEntry(filename);
                    if (!themesCssPath) {
                        filename = this.getOldThemeFilename(style.id);
                        themesCssPath = await this.webpackManifestService.getEntry(filename);
                    }
                    if (themesCssPath) {
                        await this.stylesService.loadStyle({ url: themesCssPath, lazyLoad: 'Important' });
                    }
                    break;
                case 'content':
                    const path = `App-v1.0/LazyStyles/${style.id}`;
                    const file = await firstValueFrom(this.contentService.getJson(path, { product: ClientConfigProductName.SF }));
                    await this.stylesService.add(style.id, file.content);
                    break;
            }
        }
    }
    //todo: remove when everyone migrated to esbuild
    private getOldThemeFilename(entry: string) {
        return `themes-${this.page.theme}-${entry}.css`;
    }

    private getNewThemeFilename(entry: string) {
        return `${this.page.theme}/themes-${this.page.theme}-${entry}.css`;
    }
}
