import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiServiceFactory, ClientConfigProductName, ProductService } from '@frontend/vanilla/core';

import { ApiService } from './api.service';

@NgModule()
export class ApiModule {
    static forRoot(): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [{ provide: ApiService, deps: [ApiServiceFactory, ProductService], useFactory: apiServiceFactory }],
        };
    }
}

export function apiServiceFactory(apiServiceFactory: ApiServiceFactory, productService: ProductService) {
    return apiServiceFactory.create(ApiService, {
        product: ClientConfigProductName.POKER,
        area: 'mobilepoker',
        forwardProductApiRequestHeader: productService.getMetadata(ClientConfigProductName.POKER).isEnabled,
    });
}
