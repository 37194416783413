import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { DynamicHtmlDirective, NativeAppService, UserService } from '@frontend/vanilla/core';

import { ClientRTMSResourceService } from './client-rtms-resource.service';

interface MessageToNativeInterface {
    getRtmsData(eventNm: any): void;
    closeContainer(param: any): void;
    NativeDispatch(eventName: any, param: any): void;
}
declare let MessageToNative: MessageToNativeInterface;

export interface CtaDetails {
    link: string;
    nativeEvent: string;
}

@Component({
    selector: 'pt-client-rtms',
    templateUrl: 'client-rtms.component.html',
    imports: [CommonModule, DynamicHtmlDirective],
    standalone: true,
})
export class ClientRTMSComponent implements OnInit {
    initData: any;
    content: any;
    promoData: any;
    notificationType: any;
    basetemplateId1: any;
    basetemplateId2: any;
    overlayTitle: any;
    overlayImage: any;
    overLayDescription: any;
    overlayCTA: any;
    overlayManualTermsAndConditions: any;
    tosterImage: any;
    toasterDescription: any;
    toasterCTA: any;
    isOverlay: boolean;
    index1: number;
    index2: number;
    overlayCtaDetails: CtaDetails = {
        link: '',
        nativeEvent: '',
    };
    toasterCtaDetails: CtaDetails = {
        link: '',
        nativeEvent: '',
    };

    constructor(
        private nativeApp: NativeAppService,
        private userService: UserService,
        public rtmsService: ClientRTMSResourceService,
    ) {}

    ngOnInit(): void {
        const userAgent: any = navigator.userAgent;
        if (userAgent.match(/Android/)) {
            MessageToNative.getRtmsData('GET_RTMS_DATA');
        } else this.requestRTMSContent();

        this.nativeApp.eventsFromNative.subscribe((e) => {
            if (e.eventName === 'SET_RTMS_DATA') {
                this.notificationType = e.parameters?.notificationType;
                this.basetemplateId1 = e.parameters?.baseTempletId;
                this.promoData = e.parameters;
                this.basetemplateId2 = this.basetemplateId1.replace(/(\/id\/)/g, '');
                if (this.notificationType.toLowerCase() === 'overlay') this.isOverlay = true;
                else this.isOverlay = false;
                this.getSitecoreData();
            }
        });
    }

    getSitecoreData() {
        const userAgent: any = navigator.userAgent;
        this.rtmsService.getInitValues(this.notificationType, this.basetemplateId2).subscribe(
            (response: any) => {
                this.initData = response.rtmsData;

                this.overlayImage =
                    this.initData && this.initData.overlayImage && this.initData.overlayImage.src
                        ? this.initData.overlayImage.src
                        : this.initData && this.initData.detailImage && this.initData.detailImage.src;

                this.overlayTitle =
                    this.initData && this.initData.overlayTitle ? this.initData.overlayTitle : this.initData && this.initData.commonTitle;

                this.overLayDescription =
                    this.initData && this.initData.overLayDescription
                        ? this.initData.overLayDescription
                        : this.initData && this.initData.commonDescription;

                this.overlayCTA = this.initData && this.initData.overlayCTA ? this.initData.overlayCTA : this.initData && this.initData.commonCTA;

                this.index1 = this.overlayCTA ? this.overlayCTA.search('href=') : -1;
                if (this.index1 >= 0) {
                    this.index2 = this.overlayCTA.search(' class=');
                    this.overlayCtaDetails.link = this.overlayCTA.substring(this.index1 + 6, this.index2 - 1);
                }

                this.index1 = this.overlayCTA ? this.overlayCTA.search('data-send-native=') : -1;
                if (this.index1 >= 0) {
                    this.index2 = this.overlayCTA.search('><strong>');
                    this.overlayCtaDetails.nativeEvent = this.overlayCTA.substring(this.index1 + 18, this.index2 - 1);
                }

                this.overlayManualTermsAndConditions =
                    this.initData && this.initData.overlayManualTermsAndConditions
                        ? this.initData.overlayManualTermsAndConditions
                        : this.initData && this.initData.commonTermsAndConditions;

                this.tosterImage =
                    this.initData && this.initData.tosterImage && this.initData.tosterImage.src
                        ? this.initData.tosterImage.src
                        : this.initData && this.initData.preAcceptanceImage && this.initData.preAcceptanceImage.src;

                this.toasterDescription =
                    this.initData && this.initData.toasterDescription
                        ? this.initData.toasterDescription
                        : this.initData && this.initData.postAcceptanceDescription;

                this.toasterCTA =
                    this.initData && this.initData.toasterCTA ? this.initData.toasterCTA : this.initData && this.initData.postAcceptanceCTA;

                this.index1 = this.toasterCTA ? this.toasterCTA.search('href=') : -1;
                if (this.index1 >= 0) {
                    this.index2 = this.toasterCTA.search(' data-send-native');
                    this.toasterCtaDetails.link = this.toasterCTA.substring(this.index1 + 6, this.index2 - 1);
                }

                this.index1 = this.toasterCTA ? this.toasterCTA.search('data-send-native=') : -1;
                if (this.index1 >= 0) {
                    this.index2 = this.toasterCTA.search('>');
                    this.toasterCtaDetails.nativeEvent = this.toasterCTA.substring(this.index1 + 18, this.index2 - 1);
                }
            },
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            (error) => {
                if (userAgent.match(/Android/)) {
                    MessageToNative.closeContainer({ eventName: 'RTMS_ERROR', parameters: { params: this.promoData } });
                } else this.nativeApp.sendToNative({ eventName: 'RTMS_ERROR', parameters: { params: this.promoData } });
            },
        );
    }

    linkToAction(promoCta: any) {
        const clientEvent = promoCta.nativeEvent;
        const userAgent: any = navigator.userAgent;
        if (clientEvent) {
            if (userAgent.match(/Android/)) {
                MessageToNative.NativeDispatch(clientEvent, null);
                MessageToNative.closeContainer(clientEvent);
            } else {
                this.nativeApp.sendToNative({ eventName: clientEvent, parameters: {} });
                this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
            }
        } else {
            const sso = this.userService.ssoToken;
            window.open(promoCta.link + `?sessionKey=${sso}`);
            if (userAgent.match(/Android/)) {
                MessageToNative.closeContainer(clientEvent);
            } else {
                this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
            }
        }
    }

    closeContainer() {
        if (this.nativeApp.isDownloadClient) this.nativeApp.sendToNative({ eventName: 'closeContainer', parameters: {} });
    }

    requestRTMSContent() {
        this.nativeApp.sendToNative({ eventName: 'GET_RTMS_DATA' });
    }
}
