import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { ApiService } from '../api/api.service';

@Injectable({ providedIn: 'root' })
export class ClientRTMSResourceService {
    constructor(private api: ApiService) {}

    getInitValues(type: string, id?: string): Observable<any> {
        return this.api.get(`clientrtms/GetInitValues`, { notificationType: type, basetemplateId: id });
    }
}
