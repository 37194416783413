import { Injectable } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { ClientConfigProductName, EventContext, LazyClientConfig, LazyClientConfigBase, LazyClientConfigService } from '@frontend/vanilla/core';

export interface LoadOptions {
    /** The unique id of the loader. If provided, loader with provided id will be used */
    id?: string;
    enabled: string;
    strategy: string;
    delay: number;
    styles?: StyleOptions[];
    events?: { [key: string]: string[] };
    eventContext?: EventContext<any>;
}

export interface StyleOptions {
    /** The unique id of the chunk */
    id: string;
    /** The chunk type. theme will be looked in manifest.json, url represents any external file that will be loaded, content will be added as <style id="id">data</style> */
    type: 'theme' | 'url' | 'content';
    /** When specified, indicates the css class added to html node */
    htmlCssClass?: string;
}

@LazyClientConfig({ key: 'vnLazyFeatures', product: ClientConfigProductName.SF })
@Injectable({
    providedIn: 'root',
    useFactory: loadersConfigFactory,
    deps: [LazyClientConfigService],
})
export class LoadersConfig extends LazyClientConfigBase {
    rules: { [id: string]: LoadOptions };
}

export function loadersConfigFactory(service: LazyClientConfigService) {
    return service.get(LoadersConfig);
}
