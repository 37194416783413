import { ModuleWithProviders, NgModule } from '@angular/core';

import { PlainLinkComponent, runOnAppInit } from '@frontend/vanilla/core';
import { VCClientService } from '@pokercore/module/core';

import { ClientLinkGlobalHandler } from '../poker-common-services/client-link-global-handler';
import { GoogleTagManagerRendererService } from '../poker-common-services/googleTagManager-renderer.service';
import { PokerBootstrapService } from './poker-bootstrap.service';

@NgModule({
    imports: [PlainLinkComponent],
})
export class PokerBootstrapModule {
    public static forRoot(): ModuleWithProviders<PokerBootstrapModule> {
        return {
            ngModule: PokerBootstrapModule,
            providers: [VCClientService, ClientLinkGlobalHandler, GoogleTagManagerRendererService, runOnAppInit(PokerBootstrapService)],
        };
    }
}
