import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { CookieService, NativeAppService, Page } from '@frontend/vanilla/core';
import { DownloadClientConfig, VCClientService } from '@pokercore/module/core';

@Injectable({ providedIn: 'root' })
export class PokerAppLauncherGuard implements CanActivate {
    launchProtocol: any;
    product: string;
    launchURL: string;
    action: string;
    server: string;
    port: string;
    category: string;
    ugUrl: string;
    suUrl: string;
    extendedAttr: string;

    constructor(
        private nativeAppService: NativeAppService,
        private downloadClientConfig: DownloadClientConfig,
        private vnPageService: Page,
        private cookieService: CookieService,
        private vcClientService: VCClientService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const queryParams = route.queryParams;
        if (this.vcClientService.isClient() || this.vcClientService.isMacDesktop() || this.vcClientService.isMobileApp()) {
            this.applicationLauncher(queryParams);
            return false;
        } else {
            return true;
        }
    }

    applicationLauncher(queryParams: any) {
        this.action = queryParams['A'];
        this.server = queryParams['S'];
        this.port = queryParams['PO'];
        this.category = queryParams['C'];
        this.ugUrl = queryParams['UG'];
        this.suUrl = queryParams['SU'];
        this.extendedAttr = queryParams['EX'];

        this.product = this.vnPageService.domain.substring(1, this.vnPageService.domain.lastIndexOf('.'));
        this.launchProtocol = 'launcher-' + this.product.toLowerCase();
        this.launchURL = this.launchProtocol + '://-P=' + this.product;
        if (this.server) {
            this.launchURL += ' -S=' + this.server;
        }
        if (this.port) {
            this.launchURL += '#' + this.port;
        }
        if (this.action) {
            this.launchURL += ' -action=' + this.action;
        }
        if (this.category) {
            this.launchURL += ' -category=' + this.category;
        }
        if (this.ugUrl) {
            this.launchURL += ' -UG=' + this.ugUrl;
        }
        if (this.extendedAttr) {
            this.launchURL += ' -extendedAttr=' + this.extendedAttr;
        }
        if (this.suUrl) {
            this.launchURL += ' -SU=' + this.suUrl;
        }

        let nativeEvent = this.isActionInNativeLauncherEvents(this.action);

        if (this.action == 'openTourneylobby' && nativeEvent) {
            if (nativeEvent?.includes('{tournamentId}')) {
                nativeEvent = this.replacePlaceholder(nativeEvent, 'tournamentId', this.extendedAttr);
            }
        }

        if (this.action && nativeEvent) {
            this.nativeAppService.sendToNative({ eventName: nativeEvent });
        }
    }

    isActionInNativeLauncherEvents(action: string): string | null {
        const eventsArray = this.downloadClientConfig.nativeLauncherEvents;

        for (const eventObj of eventsArray) {
            if (eventObj.key === action) {
                return eventObj.value;
            }
        }
        return null;
    }

    replacePlaceholder(template: string, placeholder: string, replacementValue: string): string {
        const regex = new RegExp(`\\{${placeholder}\\}`, 'g');
        return template.replace(regex, replacementValue);
    }
}
