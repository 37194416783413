import { Injectable } from '@angular/core';

import { NavigationService } from '@frontend/vanilla/core';
import { MyGameDataService } from '@pokercore/module';
import { MyGameClientConfig } from '@pokercore/module/core';

@Injectable({ providedIn: 'root' })
export class MyGameLoginGuard {
    host: string;
    constructor(
        private myGameDataService: MyGameDataService,
        private navigation: NavigationService,
        private myGameClientConfig: MyGameClientConfig,
    ) {}

    canActivate(): boolean {
        this.myGameDataService.getPlayerCredentials().subscribe((playerCreds: any) => {
            this.host = this.myGameClientConfig.redirectLoginUrlHost;
            const url = `${this.host}clientLogin?partnerToken=${playerCreds?.partneR_TOKEN}&accountId=${playerCreds?.accountid}`;
            this.navigation.goTo(url);
        });
        return false;
    }
}
