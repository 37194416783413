import { Injectable } from '@angular/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { OnAppInit } from '@frontend/vanilla/core';
import { firstValueFrom } from 'rxjs';

import { LoaderService } from './loader.service';
import { LoadersConfig } from './loaders.client-config';

@Injectable()
export class LoadersBootstrapService implements OnAppInit {
    constructor(private config: LoadersConfig, private loaderService: LoaderService) {}

    async onAppInit() {
        await firstValueFrom(this.config.whenReady);
        this.loaderService.init();
    }
}
