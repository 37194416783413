import { APP_BASE_HREF } from '@angular/common';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { provideLoaders } from '@frontend/loaders';
import { AppComponent, bootloader, provideVanillaCore } from '@frontend/vanilla/core';

import { AppModule } from './app.module';
import { appRoutes } from './app.routes';
import { environment } from './environments/environment';
import { providePokerCore } from './pokercore.feature';

if (environment.production) {
    enableProdMode();
}

// performance.mark('app-boot-start');
bootloader().then(() => {
    // performance.mark('app-client-config-ready');
    // performance.measure('client-config', 'app-boot-start', 'app-client-config-ready');

    bootstrapApplication(AppComponent, {
        providers: [
            importProvidersFrom(AppModule),
            provideRouter(appRoutes),
            provideVanillaCore(),
            provideAnimations(),
            provideLoaders(),
            providePokerCore(),
            { provide: APP_BASE_HREF, useValue: '/' },
        ],
    })
        .then(() => {
            // performance.mark('app-boot-end');
            // performance.measure('app-boot', 'app-boot-start', 'app-boot-end');
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.error(err));
});
