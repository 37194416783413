export const VANILLA_LAZY_ROUTES = [
    {
        path: 'logout',
        loadChildren: () => import('@frontend/vanilla/features/logout-page').then((m) => m.ROUTES),
    },
    {
        path: 'nativeapp',
        loadChildren: () => import('@frontend/vanilla/features/native-app').then((m) => m.ROUTES),
    },
    {
        path: 'inbox',
        loadChildren: () => import('@frontend/vanilla/features/inbox').then((m) => m.ROUTES),
    },
    {
        path: 'labelhost',
        loadChildren: () => import('@frontend/vanilla/features/login').then((m) => m.ROUTES), // TODO: Change to new login routes
    },
    {
        path: 'menu',
        loadChildren: () => import('@frontend/vanilla/features/account-menu').then((m) => m.ROUTES),
    },
    {
        path: 'product-menu',
        loadChildren: () => import('@frontend/vanilla/features/product-menu').then((m) => m.ROUTES),
    },
    {
        path: 'profile',
        loadChildren: () => import('@frontend/vanilla/features/navigation-layout').then((m) => m.PROFILEPAGE_ROUTES),
    },
    {
        path: 'preferences',
        loadChildren: () => import('@frontend/vanilla/features/navigation-layout').then((m) => m.DARKMODE_ROUTES),
    },
    {
        path: 'settings',
        loadChildren: () => import('@frontend/vanilla/features/navigation-layout').then((m) => m.NAVIGATIONLAYOUT_ROUTES),
    },
    {
        path: 'account',
        loadChildren: () => import('@frontend/vanilla/features/balance-breakdown').then((m) => m.ROUTES),
    },
    {
        path: 'remember-me-logout',
        loadChildren: () => import('@frontend/vanilla/features/remember-me-logout-prompt').then((m) => m.ROUTES),
    },
    {
        path: 'gaming-declaration',
        loadChildren: () => import('@frontend/vanilla/features/player-gaming-declaration').then((m) => m.ROUTES),
    },
    {
        path: 'cashier',
        loadChildren: () => import('@frontend/vanilla/features/cashier').then((m) => m.ROUTES),
    },
];
