import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PokerDownloadDeactivateGuard } from './poker-download-deactivate-guard';

@Component({
    selector: 'poker-client-download',
    templateUrl: 'poker-client-download.component.html',
    imports: [CommonModule, RouterModule],
    providers: [PokerDownloadDeactivateGuard],
    standalone: true,
})
export class PokerClientDownloadComponent {}
