import { Injectable } from '@angular/core';

import { TagManagerService, TrackingService, WindowRef } from '@frontend/vanilla/core';
import { GTMClientConfig } from '@pokercore/module/core';

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerRendererService {
    private isGoogleTagManagerLoaded: boolean = false;
    constructor(
        private tagManagerService: TagManagerService,
        private vanillaTrackingService: TrackingService,
        private windowRef: WindowRef,
        private gtmClientConfig: GTMClientConfig,
    ) {}

    renderGTMTag(): void {
        this.gtmClientConfig.whenReady.subscribe(() => {
            if (this.gtmClientConfig && this.gtmClientConfig.triggerGTMClientInjection && !this.isGoogleTagManagerLoaded) {
                // Fire App Loaded Event for use as a trigger in GTM - Should be the first event in DataLayer
                this.vanillaTrackingService.triggerEvent('Vanilla_Portal_App_Loaded');

                // Load GTM with a delay
                this.windowRef.nativeWindow.setTimeout(() => {
                    this.loadGTM();
                }, this.gtmClientConfig.gtmInjectionDelay);
            }
        });
    }

    private loadGTM(): void {
        // Load GTM Script
        this.tagManagerService.whenReady.subscribe(() => {
            this.tagManagerService.load('GoogleTagManagerRenderer');
            this.isGoogleTagManagerLoaded = true;
        });
    }
}
