import { Injectable } from '@angular/core';

import { DslService, DynamicLayoutService, NativeAppService, OnAppInit, ProductBootstrapper, SlotName, WindowRef } from '@frontend/vanilla/core';
import { OnAppInitHandler, PokerDiscountRibbonComponent, PokerRibbonTimerComponent, PokerTournamentRibbonComponent } from '@pokercore/module';
import { RibbonTimerCountdownClientConfig, TopRibbonInjectionConfig } from '@pokercore/module/core';
import { Subject } from 'rxjs';

import { ClientLinkGlobalHandler } from '../poker-common-services/client-link-global-handler';
import { GoogleTagManagerRendererService } from '../poker-common-services/googleTagManager-renderer.service';

@Injectable({ providedIn: 'root' })
export class PokerBootstrapService implements ProductBootstrapper, OnAppInit {
    private deactivate = new Subject<void>();
    constructor(
        private onAppInitHandler: OnAppInitHandler,
        private windowRef: WindowRef,
        private dynamicLayoutService: DynamicLayoutService,
        private ribbonTimerConfig: RibbonTimerCountdownClientConfig,
        private clientLinkGlobalHandler: ClientLinkGlobalHandler,
        private nativeAppService: NativeAppService,
        private gtmRendererService: GoogleTagManagerRendererService,
        private _top: TopRibbonInjectionConfig,
        private _vanDslService: DslService,
    ) {
        if (this.nativeAppService.isNative) {
            window.addEventListener('click', (event: any) => {
                this.clientLinkGlobalHandler.initClientLinkGlobalHandler(event);
            });
        }
    }

    onLoad() {}
    onActivate() {}
    onDeactivate(): void | Promise<void> {
        this.deactivate.next();
        this.deactivate.complete();
    }

    onAppInit() {
        this.onAppInitHandler.execute();
        this.subscribeToWindowPrerenderReady();
        this.gtmRendererService.renderGTMTag();

        this.ribbonTimerConfig.whenReady.subscribe(() => {
            this._vanDslService.evaluateExpression(this._top.enableTopRibbon).subscribe((c) => {
                if (c) {
                    if (this.ribbonTimerConfig.enableRibbonTimerCountdown) {
                        this.registerPokerRibbonSlot();
                    }
                    if (this.ribbonTimerConfig.enablePokerDiscountRibbonComponent) {
                        this.registerPokerDiscountRibbonSlot();
                    }
                    if (this.ribbonTimerConfig.enablePokerTournamentRibbonComponent) {
                        this.registerPokerTournamentRibbonSlot();
                    }
                } else {
                    this.dynamicLayoutService.removeComponent(SlotName.Messages, PokerRibbonTimerComponent);
                    this.dynamicLayoutService.removeComponent(SlotName.Messages, PokerDiscountRibbonComponent);
                    this.dynamicLayoutService.removeComponent(SlotName.Messages, PokerTournamentRibbonComponent);
                }
            });
        });
    }

    private subscribeToWindowPrerenderReady(): void {
        setTimeout(() => {
            if (document.getElementById('poker-prerender')) {
                (document.getElementById('poker-prerender') as HTMLElement).innerHTML = 'window.prerenderReady = true';
                this.windowRef.nativeWindow.prerenderReady = true;
            }
        }, 4000);
    }

    private registerPokerRibbonSlot(): void {
        this.dynamicLayoutService.addComponent(SlotName.Messages, PokerRibbonTimerComponent);
    }

    private registerPokerDiscountRibbonSlot(): void {
        this.dynamicLayoutService.addComponent(SlotName.Messages, PokerDiscountRibbonComponent);
    }

    private registerPokerTournamentRibbonSlot(): void {
        this.dynamicLayoutService.addComponent(SlotName.Messages, PokerTournamentRibbonComponent);
    }
}
