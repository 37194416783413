import { Injectable } from '@angular/core';

import { NativeAppService } from '@frontend/vanilla/core';

@Injectable({ providedIn: 'root' })
export class ClientLinkGlobalHandler {
    constructor(private nativeApp: NativeAppService) {}

    initClientLinkGlobalHandler(e: any) {
        let dsendNative = e.target.getAttribute('data-send-native');
        const dpUrl = e.target.getAttribute('data-purl');

        if (dsendNative != null && dsendNative != undefined) {
            if (dpUrl != null && dpUrl != undefined) {
                dsendNative = dsendNative + '&purl=' + dpUrl;
            }
            this.nativeApp.sendToNative({ eventName: dsendNative, parameters: {} });
        }
    }
}
